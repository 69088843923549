@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Regular.eot');
  src: url('../fonts/Roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
    url('../fonts/Roboto/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-500.eot');
  src: url('../fonts/Roboto/Roboto-500.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto/Roboto-500.woff2') format('woff2'), url('../fonts/Roboto/Roboto-500.woff') format('woff'),
    url('../fonts/Roboto/Roboto-500.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-700.eot');
  src: url('../fonts/Roboto/Roboto-700.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto/Roboto-700.woff2') format('woff2'), url('../fonts/Roboto/Roboto-700.woff') format('woff'),
    url('../fonts/Roboto/Roboto-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
