/**
 * the panel itself: styled to look like a proper Leaflet control
 */

.leaflet-biglegend-control {
    /* basic CSS to look like a proper Leaflet control */
    border-radius: 3px;
    background-color: white;
    box-shadow: 0px 1px 5px rgba(0,0,0,0.65);

    /* hacks to visually anchor this into the bottom-left corner: negative margin, radius on only one corner, etc. */
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
}


/**
 * CONTENTS including SWATCHES
 */
.leaflet-biglegend-control {
    padding: 0.5em 1em 0.5em 1em;
}

.leaflet-biglegend-control > div {
    font-family: Roboto;
    font-size: 14px;

    margin: 0;
}

.leaflet-biglegend-control div.leaflet-biglegend-swatch {
    display: inline-block;
    width: 1em;
    height: 1em;

    vertical-align: text-top;

    margin-right: 3px;
    margin-left: 2px;

    -webkit-print-color-adjust: exact;

    border: 1px solid transparent;  /* override this for any specific swatch e.g. inline styles */
    background-color: transparent;  /* override this for any specific swatch e.g. inline styles */
}
.leaflet-biglegend-control div.leaflet-biglegend-swatch.leaflet-biglegend-swatch-square {
}
.leaflet-biglegend-control div.leaflet-biglegend-swatch.leaflet-biglegend-swatch-circle {
    border-radius: 50%;
}
.leaflet-biglegend-control div.leaflet-biglegend-swatch.leaflet-biglegend-swatch-line {
    height: 0.3em;
    margin-top: 0.4em;
}
.leaflet-biglegend-control div.leaflet-biglegend-swatch.leaflet-biglegend-swatch-image {
    background-size: contain;
    width: 16px;
    height: 16px;
    vertical-align: bottom;
}
