/**
 * the main panel itself: styled to look like a proper Leaflet control
 * and showing the toggle button and (when not colapsed) the big panel
 */

.leaflet-biglayerpanel-control {
    /* basic CSS to look like a proper Leaflet control */
    border-radius: 3px;
    background-color: white;
    box-shadow: 0px 1px 5px rgba(0,0,0,0.65);

    /* hacks to visually anchor this into the top-left corner: negative margin, radius on only one corner, etc. */
    margin-top: 0 !important;
    margin-left: 0 !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}

/* z-index hack for super short screens (Street View on) so Map Features comes above Print/Export buttons */
div.mapViewMainMap div.leaflet-control-container div.leaflet-top.leaflet-left {
    z-index: 1001;
}

/* tiny screen = don't occlude other map controls; reasonable screen = wide display to prevent line-wrap */
@media screen and (max-width: 767px) {
    .leaflet-biglayerpanel-control {
        max-width: calc(100% - 65px);
    }
}
@media screen and (min-width: 768px) {
    .leaflet-biglayerpanel-control .leaflet-biglayerpanel-panel {
        width: 4.5in;
    }
}


/**
 * MAX-HEIGHT BEHAVIOR in case the control content gets too big, to do scrolling instead of going beyond bottom of map
 */

.leaflet-biglayerpanel-control .leaflet-biglayerpanel-panel {
}
.leaflet-biglayerpanel-control .leaflet-biglayerpanel-accordion {
    height: 100%;
    max-height: calc(4.5in - 30px);  /* make up a height that fits to the map nicely */
    overflow-y: auto;
}


/**
 * THE TOGGLE BUTTON always displayed, to expand/collapse the control's main panel
 */

.leaflet-biglayerpanel-control .leaflet-biglayerpanel-header {
    background-color: #cccccc;

    padding: 5px 5px 5px 10px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #0D0D0D;

    cursor: pointer;
}

.leaflet-biglayerpanel-control .leaflet-biglayerpanel-header button {  /* when expanded, a visual X to remind them that this closes the panel */
    float: right;
    padding: 0;
    margin-top: -3px;

    background-color: transparent;
    border: 0 none transparent;
    cursor: pointer;

    font-size: 25px;
    font-weight: bold;
    color: black !important;
    text-decoration: none !important;
}

.leaflet-biglayerpanel-control .leaflet-biglayerpanel-header button[aria-expanded="false"] {
    display: none;
}
.leaflet-biglayerpanel-control .leaflet-biglayerpanel-header button[aria-expanded="true"] {
    display: inline-block;
}

.leaflet-biglayerpanel-control .leaflet-biglayerpanel-panel.collapsed {
    display: none !important;
}


/**
 * WAVE workarounds
 */

.leaflet-biglayerpanel-control fieldset {
    border: 0 none transparent;
    padding: 0;
}
.leaflet-biglayerpanel-control legend {
    display: none !important;
}


/**
 * THE MAIN PANEL which shows and hides with the .collapsed state described above
 */

.leaflet-biglayerpanel-control button.leaflet-biglayerpanel-accordion-title {
    display: block;
    text-decoration: none !important;
    width: 100%;
    text-align: left;
    background-color: transparent;
    border: 0 none transparent;
    cursor: pointer;

    white-space: nowrap;

    padding: 3px 10px 3px 10px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #0D0D0D;
}
.leaflet-biglayerpanel-control button.leaflet-biglayerpanel-accordion-title:not(:first-child) {
    border-top: 1px solid #cccccc;
}

.leaflet-biglayerpanel-control div.leaflet-biglayerpanel-accordion-section {
    padding: 0 1em 1em 1em;
    font-size: 14px;
}
.leaflet-biglayerpanel-control div.leaflet-biglayerpanel-accordion-section.collapsed {
    display: none;
}

.leaflet-biglayerpanel-control button.leaflet-biglayerpanel-accordion-title img {
    margin-right: 0.5em;
    pointer-events: none;  /* chevron clicks should not happen; make them fall through to the titlebar which toggles the section */
    vertical-align: middle;
}


/**
 * LAYER ENTRIES come as two checkboxes and table-shaped layout
 * and have LEGEND SWATCHES
 */

.leaflet-biglayerpanel-control div.leaflet-biglayerpanel-layer {
    display: flex;
}
.leaflet-biglayerpanel-control div.leaflet-biglayerpanel-layer div.leaflet-biglayerpanel-layer-left {
    flex: 4;
}
.leaflet-biglayerpanel-control div.leaflet-biglayerpanel-layer div.leaflet-biglayerpanel-layer-right {
    flex: 1;
    text-align: right;
}

.leaflet-biglayerpanel-control div.leaflet-biglayerpanel-layer div.leaflet-biglayerpanel-layer-subpanel {
    margin-left: 2.1em;
}

.leaflet-biglayerpanel-control div.leaflet-biglayerpanel-layer label {
}
.leaflet-biglayerpanel-control div.leaflet-biglayerpanel-layer input[type="checkbox"] {
    vertical-align: bottom;
}

.leaflet-biglayerpanel-control div.leaflet-biglayerpanel-layer-swatch {
    display: inline-block;
    width: 1em;
    height: 1em;

    vertical-align: text-top;

    margin-right: 3px;
    margin-left: 2px;

    -webkit-print-color-adjust: exact;

    border: 0 none transparent;  /* override this for any specific swatch e.g. inline styles */
    background-color: transparent;  /* override this for any specific swatch e.g. inline styles */
}
.leaflet-biglayerpanel-control div.leaflet-biglayerpanel-layer-swatch.leaflet-biglayerpanel-layer-swatch-square {
}
.leaflet-biglayerpanel-control div.leaflet-biglayerpanel-layer-swatch.leaflet-biglayerpanel-layer-swatch-circle {
    border-radius: 50%;
}
.leaflet-biglayerpanel-control div.leaflet-biglayerpanel-layer-swatch.leaflet-biglayerpanel-layer-swatch-line {
    height: 0.3em;
    margin-top: 0.4em;
}
.leaflet-biglayerpanel-control div.leaflet-biglayerpanel-layer-swatch.leaflet-biglayerpanel-layer-swatch-image {
    background-size: contain;
    width: 16px;
    height: 16px;
}



/**
 * DEMOGRAPHICS SECTION
 */

#map-biglayerpanel-accordion-panel-demographics select.leaflet-biglayerpanel-demographics-polytype {
    display: block;
    margin-bottom: 0.5em;
    width: 100%;
}

#map-biglayerpanel-accordion-panel-demographics div.leaflet-biglayerpanel-demographics-layer {
}

#map-biglayerpanel-accordion-panel-demographics div.leaflet-biglayerpanel-demographics-layer div.leaflet-biglayerpanel-demographics-layer-legend {
    padding-left: 2.25em;
}
#map-biglayerpanel-accordion-panel-demographics div.leaflet-biglayerpanel-demographics-layer.collapsed div.leaflet-biglayerpanel-demographics-layer-legend {
    display: none;
}

#map-biglayerpanel-accordion-panel-demographics div.leaflet-biglayerpanel-demographics-layer span.leaflet-biglayerpanel-demographics-swatch {
    display: inline-block;
    width: 1em;
    height: 1em;
}
#map-biglayerpanel-accordion-panel-demographics div.leaflet-biglayerpanel-demographics-layer span.leaflet-biglayerpanel-demographics-swatch.q1 {
    background-color: rgb(222, 191, 249);
}
#map-biglayerpanel-accordion-panel-demographics div.leaflet-biglayerpanel-demographics-layer span.leaflet-biglayerpanel-demographics-swatch.q2 {
    background-color: rgb(189, 143, 228);
}
#map-biglayerpanel-accordion-panel-demographics div.leaflet-biglayerpanel-demographics-layer span.leaflet-biglayerpanel-demographics-swatch.q3 {
    background-color: rgb(160, 109, 203);
}
#map-biglayerpanel-accordion-panel-demographics div.leaflet-biglayerpanel-demographics-layer span.leaflet-biglayerpanel-demographics-swatch.q4 {
    background-color: rgb(137, 80, 185);
}
#map-biglayerpanel-accordion-panel-demographics div.leaflet-biglayerpanel-demographics-layer span.leaflet-biglayerpanel-demographics-swatch.q5 {
    background-color: rgb(108, 52, 196);
}


/**
 * STYLE HACKS to match the rest of the website
 * styles for select elements, and .radio-item false checkboxes so we can style them
 */


.leaflet-biglayerpanel-control select {
    font-size: 14px;
    border: 1px solid #B3B3B3;
    padding: 5px 15px 5px 5px;
    border-radius: 0;

    /* for the custom icon, hide the built-in handle; see the elements' own background property because we need to load images via React */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.leaflet-biglayerpanel-control .radio-item {
    display: inline-block;
    position: relative;
    padding: 0 6px;
}
.leaflet-biglayerpanel-control .radio-item input[type="radio"] {
    display: none;
}
.leaflet-biglayerpanel-control .radio-item label {
    cursor: pointer;
}
.leaflet-biglayerpanel-control .radio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 4px;
    left: 1px;
    margin: 0 5px 0 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.65); /* circle/border color */
    background-color: transparent;
}
.leaflet-biglayerpanel-control .radio-item input[type="radio"]:checked + label:before {
    border-color: #D33535; /* circle/border color when checked */
}
.leaflet-biglayerpanel-control .radio-item input[type="radio"]:checked + label:after {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 7px;
    left: 10px;
    content: " ";
    display: block;
    background: #D33535; /* filled bullet color */
}
.leaflet-biglayerpanel-control .radio-item {
    display: inline-block;
    position: relative;
    padding: 0 6px;
}

.leaflet-biglayerpanel-control .checkbox-item input[type="checkbox"] {
    display: none;
}

.leaflet-biglayerpanel-control .checkbox-item label {
    cursor: pointer;
    position: relative;
}
.leaflet-biglayerpanel-control .checkbox-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 2px;
    left: 1px;
    margin: 0 5px 0 0;
    width: 18px;
    height: 18px;
    border: 1px solid rgba(0, 0, 0, 0.65); /* circle/border color */
    background-color: transparent;
}
.leaflet-biglayerpanel-control .checkbox-item input[type="checkbox"]:checked + label:before {
    border-color: #D33535; /* circle/border color when checked */
}
.leaflet-biglayerpanel-control .checkbox-item input[type="checkbox"]:checked + label:after {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 1px;
    left: 3px;
    content: " ";
    display: inline-block;
    background: #D33535; /* filled bullet color */
}
