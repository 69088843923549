.leaflet-control-basemapbar {
    color: #0D0D0D;
    font-family: Roboto;
    font-size: 14px;
}

.leaflet-control-basemapbar {
    box-shadow: 0px 1px 5px rgba(0,0,0,0.65);
    border-radius:3px;
    background-color:white;
    cursor:pointer;
}

.leaflet-control-basemapbar > div {
    display:inline-block;
    padding:0.25em .75em 0.25em .75em;
}
.leaflet-control-basemapbar > div:last-child {
    border:none;
}


.leaflet-control-basemapbar-option:hover {
    background: #e0e0e0;
}

.leaflet-control-basemapbar-option-active {
    color:white !important;
    background-color:black !important;
}
.leaflet-control-basemapbar-option:first-child {
    border-bottom-left-radius:3px;
    border-top-left-radius:3px;
}
.leaflet-control-basemapbar-option:last-child {
    border-bottom-right-radius:3px;
    border-top-right-radius:3px;

    border-right:0 none transparent;
}

.leaflet-control-basemapbar-open {
    border-radius:3px;
}
.leaflet-control-basemapbar-open span {
    font-size: 23px;
    line-height:2;
}
.leaflet-control-basemapbar-close {
    border-top-right-radius:3px;
    border-bottom-right-radius:3px;

    font-size: 20px;
    line-height: 0;
    font-weight: bold;

    width: 28px !important;
    padding: 0 !important;
    text-align: center;
}

.leaflet-control-basemapbar .leaflet-control-basemapbar-hidden {
    display:none;
}
