/* Base styles */
* {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

html {
  overflow: hidden auto;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  min-width: 320px;
  font-size: 14px;
  font-weight: 400;
  color: #2b4e56;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  background: #f7f2f0;

  &.modal-open {
    overflow: hidden;
  }
}

ol,
ul,
li {
  list-style: none;
}

svg {
  pointer-events: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover {
    text-decoration: none;
  }
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='text'] {
  -moz-appearance: textfield;

  &::-ms-clear {
    display: none;
  }
}

input[type='password'] {
  &::-ms-reveal {
    display: none;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']:hover,
input[type='number']:focus {
  -moz-appearance: textfield;
}

@mixin shadowDrop(
  $outerOffsetX: 0px,
  $outerOffsetY: 1px,
  $outerRadius: 2px,
  $outerColor: --black-two20,
  $spreadDistance: 0
) {
  -moz-box-shadow: $outerOffsetX $outerOffsetY $outerRadius $spreadDistance $outerColor;
  -webkit-box-shadow: $outerOffsetX $outerOffsetY $outerRadius $spreadDistance $outerColor;
  box-shadow: $outerOffsetX $outerOffsetY $outerRadius $spreadDistance $outerColor;
}

button,
input[type='submit'] {
  display: block;
  background: transparent;
  border-radius: 0;
  transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
  cursor: pointer;
}

.popover {
  z-index: 6;
  max-width: fit-content !important;
  margin-top: -1px !important;
}

// Modal animations
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modalFadeDown {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes modalFadeUp {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
}

// End modal animations

// Blink animation for loading process
@keyframes blink {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

:root {
  --white: #fff;
  --soft-grey: #f9f9f9; //FIXME: remove duplicate
  --grey: #e0e0e0;
  --grey-border: #bdbdbd;
  --black-two33: rgba(0, 0, 0, 0.33);
  --black-two04: rgba(0, 0, 0, 0.04);
  --dim-grey: #737373;
  --black: #000;
  --dark: #0d0d0d;
  --dark30: rgba(13, 13, 13, 0.3);
  --danger: #d33535;
  --persian-red: #c53232;
  --ice-blue: #edf0f2;
  --sky: #bcd7fb; //FIXME: remove duplicate
  --focus: #1787e0; //FIXME: remove duplicate
  --curious-blue: #5968B2;
  --info: #3498db;
  --dodger-blue: #1787e0;
  --success: #07bc0c;
  --online: #1c824a;
  --warning: #f1c40f;
  --ice-blue: #edf0f2;
  --soft-grey: #f9f9f9; //FIXME: remove duplicate
  --lite-grey: #f2f2f2; //FIXME: remove duplicate
  --soft-dark: #3a3e43;
  --white--2: #f2f2f2; //FIXME: remove duplicate
  --curious-blue: #5968B2;
  --baltic-sea: #3a3e43;
  --active-calendar-border: #b3b3b3;
  --cod-grey: #323538;
  --table-dark-grey: #828282;
  --rgb-grey: rgb(224, 224, 224);
  --rgb-soft-grey: rgb(249, 249, 249);
  --white-smoke: #f5f5f5;
  --grey-smoke: #f3f3f3;
  --whisper: #e9e9e9;
  --dark-gray: #aeaeae;
  --gray-20: #333333;
  --opacity-grey: #f2f2f3c2;
  --opacity-light-grey: #fafafa;
  --gainsboro: #d9d9d9;
  --soft-green: #e9ffe6;
}
