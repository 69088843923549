.leaflet-credits-control {
    height:38px;        /* defined in the JavaScript code */
    padding-right:39px; /* defined in the JavaScript code */

    background-image:none; /* defined in the JavaScript code */
    background-repeat:no-repeat;
    background-position:100% 0;

    background-color:white;
    opacity:0.90;
    /*border-radius:5px;*/
    /*border:4px solid rgba(0, 0, 0, 0.1);*/

    cursor:pointer;
}
.leaflet-credits-control a {
    font-size:8pt;
    color:#333333 !important;
    text-decoration:none;

    text-align:right;

    display:none; /* becomes visible on mouse hover, see creditsControl */
}
.leaflet-credits-control a.leaflet-credits-showlink {
    display:inline-block;
    padding-left:5px;
    padding-right:5px;

    margin-top:5px;

    line-height:15px;
}

/* unlike dozens of sites that use this control, the Attribution crowds it and clips it off... */
.leaflet-container .leaflet-control-attribution {
    margin-top:10px;
}
