div.leaflet-control-zoom a {
    font: bold 18px 'Lucida Console', Monaco, monospace !important;
    line-height: 1.5 !important;
}
div.leaflet-control-zoom a.leaflet-control-zoom-in i {
}
div.leaflet-control-zoom a.leaflet-control-zoom-out i {
}
div.leaflet-control-zoom a.leaflet-control-zoom-home img {
    width: 18px;
    height: 18px;
    display: inline;
    margin-top: 5px;
}

/*
had to custom-craft the control as SPANs containing As so we could trick Firefox/Safari into tabbing to them
so here is custom border-radius code to fix the resulting problems
*/
div.leaflet-control-zoom span:nth-child(1) a {
    border-radius: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
div.leaflet-control-zoom span:nth-child(2) a {
    border-radius: 0;
}
div.leaflet-control-zoom span:nth-child(3) a {
    border-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

/* override default greying-out when disabled, because WAVE complains about contrast */
div.leaflet-control-zoom a.leaflet-disabled {
    color: black !important;
    background-color: white !important;
}
