.Toastify {
  &__toast {
    position: relative;
    z-index: 99999;
    width: 100%;
    box-shadow: 0 0 20px 0 var(--black-two33);
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    min-height: 40px;
    background-color: var(--baltic-sea);

    &-container {
      width: max-content;
      max-width: 410px;

      &--bottom-center {
        @media only screen and (max-width: 768px) {
          width: 90vw;
          left: 50%;
          bottom: 10px;
          transform: translateX(-50%);
        }
      }
    }

    //TODO: uncomment when statuses will be approved
    //&--error {
    //  border-color: var(--danger);
    //}
    //
    //&--success {
    //  border-color: var(--success);
    //}
    //
    //&--info {
    //  border-color: var(--info);
    //}
    //
    //&--warning {
    //  border-color: var(--warning);
    //}

    &-body {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0;
      color: var(--black);
      font-size: 14px;
      line-height: 21px;
    }

    &-content {
      display: flex;

      > img {
        margin: auto 0;
      }
    }

    &-text-wrapper {
      color: var(--white);
      margin-left: 10px;
      //border-left: 1px solid var(--ice-blue);
      padding: 0 10px;
      font-size: 16px;
      line-height: 1.34;
    }

    &-icon {
      margin-right: 10px;
    }

    &-text {
      flex-grow: 1;
      padding-top: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__close-button {
    display: none;
    //  margin: auto 0;
    //
    //  svg {
    //    height: 20px;
    //    width: 20px;
    //
    //    path {
    //      fill: var(--black);
    //    }
    //  }
  }
}
